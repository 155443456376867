<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="empresa-form"
        form="empresa-form"
        @submit.prevent="saveEmpresa()"
      >
        <v-card-text class="py-0">
          <v-row class="py-0">
            <v-col cols="12" md="8" class="py-0">
              <v-text-field
                dense
                outlined
                v-model.trim="empNombre"
                label="Nombre de empresa"
                :rules="
                  rules.required.concat([
                    rules.maxLength(empNombre, 100),
                    rules.requiredTrim(empNombre)
                  ])
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                dense
                outlined
                v-model.trim="cuit"
                v-mask="'##-########-#'"
                label="CUIT"
                :rules="rules.required"
                @blur="cuit !== null || cuit == '' ? validarCUIL(cuit) : []"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model.trim="direccion"
                label="Dirección"
                :rules="
                  rules.required.concat([
                    rules.maxLength(direccion, 120),
                    rules.requiredTrim(direccion)
                  ])
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-autocomplete
                dense
                outlined
                v-model="ramaSelected"
                :items="ramasSindicales"
                item-value="id"
                item-text="value"
                clearable
                label="Rama sindical"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="telefono"
                label="Teléfono"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="[rules.maxLength(telefono, 100)]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="py-0">
              <v-autocomplete
                dense
                outlined
                v-model="localidadSelected"
                :items="localidades"
                item-value="id"
                item-text="value"
                clearable
                label="Localidad"
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="codPostal"
                label="Código postal"
                v-mask="'#######'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="responsable"
                label="Responsable"
                :rules="[rules.maxLength(responsable, 100)]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="email"
                label="Email"
                :rules="rules.email.concat([rules.maxLength(email, 200)])"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal"> Cancelar</v-btn>
          <v-btn
            type="submit"
            :disabled="!validCuit && !isFormValid"
            form="empresa-form"
            color="primary"
            @click="saveEmpresa()"
          >
            Guardar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
import validateCUIL from "@/utils/helpers/validateCUIL";

export default {
  props: {
    empresa: { type: Object, required: false }
  },
  directives: { mask },
  data() {
    return {
      formEditTitle: "Nueva Empresa",
      rules: rules,
      isFormValid: false,
      empNombre: null,
      responsable: null,
      cuit: null,
      validCuit: null,
      direccion: null,
      localidadSelected: null,
      ramaSelected: null,
      localidades: [],
      ramasSindicales: [],
      observaciones: null,
      telefono: null,
      codPostal: null,
      email: null
    };
  },
  created() {
    this.setSelectCombos();
    if (this.empresa != null) {
      this.setEmpresa();
    }
  },

  methods: {
    ...mapActions({
      getLocalidades: "afiliaciones/getLocalidades",
      getRamasSindicales: "afiliaciones/getRamasSindicales",
      saveEmpresaAportante: "configuracion/saveEmpresaAportante",
      setAlert: "user/setAlert"
    }),
    async setSelectCombos() {
      this.localidades = await this.getLocalidades();
      this.ramasSindicales = await this.getRamasSindicales();
      this.empresa
        ? ((this.localidadSelected = this.empresa.locId),
          (this.ramaSelected = this.empresa.ramaId))
        : [];
    },
    setEmpresa() {
      this.formEditTitle = "Editar empresa";
      this.empNombre = this.empresa.empNombre;
      this.responsable = this.empresa.responsable;
      this.cuit = this.empresa.cuit;
      this.direccion = this.empresa.direccion;
      this.observaciones = this.empresa.observaciones;
      this.telefono = this.empresa.telefono;
      this.email = this.empresa.email;
      this.codPostal = this.empresa.codPostal;
    },
    async saveEmpresa() {
      const empresa = {
        empId: this.empresa != null ? this.empresa.empId : null,
        empNombre: this.empNombre.toUpperCase(),
        responsable: this.responsable,
        cuit: this.cuit.replaceAll("-", ""),
        direccion: this.direccion,
        locId: this.localidadSelected,
        ramaId: this.ramaSelected == 0 ? null: this.ramaSelected,
        observaciones: this.observaciones,
        email: this.email,
        telefono: this.telefono,
        codPostal: this.codPostal,
        noValidarCorreDDJJ: null
      };
      if (this.validCuit === false) {
        this.validarCUIL(this.cuit);
      } else {
        const response = await this.saveEmpresaAportante(empresa);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito"
          });
          this.updateTable();
          this.closeModal();
        }
      }
    },
    validarCUIL(cuil) {
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        this.setAlert({
          type: "warning",
          message: "El CUIL ingresado es incorrecto."
        });
        this.isFormValid = false;
        this.validCuit = false;
      } else if (
        this.empNombre !== null &&
        this.localidadSelected !== null &&
        this.direccion !== null
      ) {
        this.isFormValid = true;
        this.validCuit = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    updateTable() {
      this.$emit("applyFilters");
    }
  }
};
</script>

<style></style>
